export interface CountryInterface {
    id: string
    alpha2: string
    alpha3: string
    name: string
}

export const countries: Array<CountryInterface> = [
    { id: '32', alpha2: 'AR', alpha3: 'ARG', name: 'Argentina' },
    { id: '56', alpha2: 'BE', alpha3: 'BEL', name: 'Belgium' },
    { id: '124', alpha2: 'CA', alpha3: 'CAN', name: 'Canada' },
    { id: '170', alpha2: 'CO', alpha3: 'COL', name: 'Colombia' },
    { id: '250', alpha2: 'FR', alpha3: 'FRA', name: 'France' },
    { id: '372', alpha2: 'IE', alpha3: 'IRL', name: 'Ireland' },
    { id: '380', alpha2: 'IT', alpha3: 'ITA', name: 'Italia' },
    { id: '442', alpha2: 'LU', alpha3: 'LUX', name: 'Luxembourg' },
    { id: '724', alpha2: 'ES', alpha3: 'ESP', name: 'Spain' },
    { id: '756', alpha2: 'CH', alpha3: 'CHE', name: 'Switzerland' },
    { id: '826', alpha2: 'GB', alpha3: 'GBR', name: 'United Kingdom' },
]
