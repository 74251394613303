import { Icon } from '@chakra-ui/react'

const WorkoutIcon = (props) => (
    <Icon viewBox="0 0 137.002 137.002" {...props}>
        <g transform="translate(-345 -1478.998)">
            <path
                id="Exclusion_5"
                data-name="Exclusion 5"
                d="M19131.5,17467a68.286,68.286,0,1,1,26.664-5.383A68.065,68.065,0,0,1,19131.5,17467Zm22.061-105.4a3.4,3.4,0,0,0-1.307.3l-10.592,8.895c-.193.166-.34.287-.459.385l-.025.02a6.51,6.51,0,0,0-1.23,1.32l-9.449,11.613a125.564,125.564,0,0,0-12.1,12.354c-3.451,4.043-5.2,9.082-6.883,13.953-.477,1.379-1.018,2.941-1.584,4.416a3.155,3.155,0,0,0-.26.988l-2.949,16a3.7,3.7,0,0,0-.043.553v.078a2.854,2.854,0,0,0,2.852,2.92h.045a2.843,2.843,0,0,0,2.789-2.664l3.5-15.729,5.844-10.6a.865.865,0,0,1,.8-.52.763.763,0,0,1,.738.5l3.889,11.371,2.516,14.973a2.873,2.873,0,0,0,2.842,2.617,2.925,2.925,0,0,0,2.213-1,3.05,3.05,0,0,0,.607-2.58l-1.984-15.111a3.1,3.1,0,0,0-.174-.756l-1.822-11.1c-.025-.15-.049-.271-.072-.385a1.658,1.658,0,0,1,.457-1.855l6.6-5.666.7.279,2.125,3.975-7.584,2.525c-.863.289-1.564.6-1.426,1.475a3.565,3.565,0,0,0,1.445,2.289,1.141,1.141,0,0,0,.654.191,3.487,3.487,0,0,0,.689-.1l10.076-2.129a2.861,2.861,0,0,0,1.811-1.4,2.432,2.432,0,0,0,.088-2l-3.127-8.379a9.691,9.691,0,0,0-1.639-3.021l-.221-.271-.01-.01a22.487,22.487,0,0,1-2.988-4.3,2.778,2.778,0,0,1,.24-2.189l5.33-7.939,9.42-9.479a3.7,3.7,0,0,0,1.426-2.8v-.053A1.758,1.758,0,0,0,19153.561,17361.6Zm-6.213,16.859a3.823,3.823,0,0,0-2.037.6,13.715,13.715,0,0,0-4,4.066,5.451,5.451,0,0,0,.16,4.145,4.284,4.284,0,0,0,.973,1.291,5.681,5.681,0,0,0,3.455,1.311,3.322,3.322,0,0,0,.5-.033,13.562,13.562,0,0,0,5-2.742c1.715-1.564,1.713-4.043-.006-6.312A5.256,5.256,0,0,0,19147.348,17378.461Z"
                transform="translate(-18718 -15851)"
                fill="#000060"
            />
        </g>
    </Icon>
)

export default WorkoutIcon
