import { Box, Button, Heading, Img, Text, useStyleConfig, useBreakpointValue, Show } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { AppPropsWithLayout, NextPageWithLayout } from '~pages/_app'
import useTranslation from 'next-translate/useTranslation'
import { routes } from '~components/sielbleu/utils/routes'
import { useRouter } from 'next/router'
import { RESPIFIL } from '~components/sielbleu/utils/const'
import ScheduleIcon from '~ui/icons/ScheduleIcon'
import WorkoutIcon from '~ui/icons/WorkoutIcon'
import CallCoachIcon from '~ui/icons/CallCoachIcon'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { GetServerSidePropsContext } from 'next'
import { countries, CountryInterface } from '~components/sielbleu/register/utils/countries'
import { clientGeo } from '~components/sielbleu/utils/clientGeo'
import Head from 'next/head'
import HeadLinks from '~components/sielbleu/head/links'
import Layout from '~components/sielbleu/layout'
import TransText from 'next-translate/TransText'

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const { req } = context
    const geo = clientGeo(req)

    return {
        props: { countryCode: geo?.country || null },
    }
}

const Page: NextPageWithLayout = ({ countryCode }: AppPropsWithLayout['pageProps']) => {
    const { t, lang } = useTranslation('sielbleu')
    const router = useRouter()
    const { query } = router
    const imgPath = '/images/sielbleu/'
    const devicesImage = useBreakpointValue({
        base: imgPath + 'landing/devices_webmobile.png',
        md: imgPath + 'landing/devices_web.png',
    })
    const visitorCountry = countries.find((country: CountryInterface) => {
        return country.alpha2 === countryCode
    })
    const pageStyles = useStyleConfig('Landing', { variant: RESPIFIL })

    const handleRegisterRedirection = () => {
        window.location = ('//' +
            process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN +
            '/' +
            lang +
            routes.public.register +
            '?' +
            new URLSearchParams({
                ...query,
                type: RESPIFIL,
                country: visitorCountry?.alpha2 || '',
            }).toString()) as any
    }

    const partners = [
        {
            link: 'https://www.adcp.asso.fr/',
            name: 'association adcp',
            path: 'respifil-apa/logo_adcp.png',
        },
        {
            link: 'https://www.asso-fpf.com/',
            name: 'association fpf',
            path: 'partners/logo_afpf.png',
        },
        {
            link: 'https://coeurcouleur.wixsite.com/asso-coeur-couleur',
            name: 'association coeur couleur',
            path: 'respifil-apa/logo_coeur_couleur.png',
        },
        {
            link: 'http://www.htapfrance.com/',
            name: 'htap',
            path: 'respifil-apa/logo_htap.png',
        },
    ]

    return (
        <>
            <HeadLinks />
            <Head>
                <title>Respifil - {t('sielbleu.landing.adapted-physical-activity')}</title>
                <meta name="description" content={t('sielbleu.landing.respifil.description.text')} />
                <meta property="og:title" content={t('sielbleu.landing.respifil.title')} />
                <meta property="og:site_name" content="Respifil" />
                <meta property="og:url" content={process.env.NEXT_PUBLIC_LANDING_RESPIFIL_URL} />
                <meta property="og:description" content={t('sielbleu.landing.respifil.description.text')} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content={
                        process.env.NEXT_PUBLIC_LANDING_RESPIFIL_URL + '/images/sielbleu/respifil-apa/header_image.jpg'
                    }
                />
            </Head>
            <Box __css={pageStyles}>
                <Box className="header-banner">
                    <Heading className="main-title">{t('sielbleu.landing.respifil.title')}</Heading>
                    <Heading className="motivation-title">{t('sielbleu.landing.respifil.motivation')}</Heading>
                    <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                </Box>
                <Box className="container">
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">
                                {t('sielbleu.landing.respifil.description.title')}
                            </Heading>
                            <Text className="section">{t('sielbleu.landing.respifil.description.text')}</Text>
                        </Box>
                        <Img src={devicesImage} alt="devices" />
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.respifil.details.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-blue">
                                <ScheduleIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.respifil.details.card-1.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.respifil.details.card-1.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <WorkoutIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.respifil.details.card-2.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.respifil.details.card-2.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <CallCoachIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.respifil.details.card-3.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.respifil.details.card-3.text')}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.respifil.pro.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-white">
                                <Box className="card-white-title one" />
                                <Text className="section">{t('sielbleu.landing.respifil.pro.card-1.text')}</Text>
                            </Box>
                            <Box className="card-white">
                                <Box className="card-white-title two" />
                                <Text className="section">
                                    <TransText
                                        text={t('sielbleu.landing.respifil.pro.card-2.text')}
                                        components={{
                                            link: (
                                                <a
                                                    style={{ textDecoration: 'underline' }}
                                                    href={'https://erj.ersjournals.com/content/52/suppl_62/PA4786'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                />
                                            ),
                                        }}
                                    />
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.respifil.partners')}</Heading>
                        <Show below="md">
                            <Carousel infiniteLoop={true} showThumbs={false} showStatus={false} showIndicators={false}>
                                {partners.map((l) => {
                                    return (
                                        <a href={l.link} key={l.name} target="_blank" rel="noreferrer">
                                            <Img src={imgPath + l.path} alt={l.name} />
                                        </a>
                                    )
                                })}
                            </Carousel>
                        </Show>
                        <Show above="md">
                            <Box display="flex" justifyContent={'space-around'}>
                                {partners.map((l) => {
                                    return (
                                        <a href={l.link} key={l.name} target="_blank" rel="noreferrer">
                                            <Img src={imgPath + l.path} alt={l.name} />
                                        </a>
                                    )
                                })}
                            </Box>
                        </Show>
                    </Box>
                    <Box className="card-full-blue">
                        <Heading>{t('sielbleu.landing.fpi.customized')}</Heading>
                        <Text>{t('sielbleu.landing.fpi.text')}</Text>
                        <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const PageLanding = Page

PageLanding.getLayout = function getLayout(page: ReactElement) {
    return <Layout>{page}</Layout>
}

export default PageLanding
