import { Icon } from '@chakra-ui/react'

const ScheduleIcon = (props) => (
    <Icon viewBox="0 0 137 137.004" {...props}>
        <g transform="translate(-344.916 -1478.998)">
            <path
                id="Exclusion_4"
                data-name="Exclusion 4"
                d="M19648.5,17467a68.3,68.3,0,1,1,26.662-5.385A68.062,68.062,0,0,1,19648.5,17467Zm-21.887-97.264a3.841,3.841,0,0,0-3.834,3.84v57.979a3.841,3.841,0,0,0,3.834,3.84h43.768a3.842,3.842,0,0,0,3.836-3.84v-57.979a3.842,3.842,0,0,0-3.836-3.84h-5.789V17374h5.359v57.127h-42.906V17374h5.363v-4.266Zm6.314,48.037a.834.834,0,0,0-.6.254l-.729.723a.868.868,0,0,0,0,1.211l3.232,3.227a.849.849,0,0,0,1.207,0l5.721-5.715a.863.863,0,0,0,0-1.207l-.729-.723a.83.83,0,0,0-.6-.252.85.85,0,0,0-.6.252l-4.389,4.385-1.9-1.9A.854.854,0,0,0,19632.93,17417.775Zm11.852,2.543v0l.1,2.844h19.869v-2.844h-19.967Zm-11.852-14.166a.837.837,0,0,0-.6.252l-.729.723a.874.874,0,0,0,0,1.213l3.232,3.227a.85.85,0,0,0,.6.252.837.837,0,0,0,.6-.252l5.721-5.711a.87.87,0,0,0,0-1.213l-.729-.721a.834.834,0,0,0-.6-.254.854.854,0,0,0-.6.254l-4.389,4.383-1.9-1.9A.857.857,0,0,0,19632.93,17406.152Zm11.852,2.541v0l.1,2.852h19.869v-2.852h-19.967Zm-11.852-14.16a.851.851,0,0,0-.6.248l-.729.723a.863.863,0,0,0,0,1.213l3.232,3.23a.865.865,0,0,0,.6.248.851.851,0,0,0,.6-.248l5.721-5.715a.87.87,0,0,0,0-1.213l-.729-.723a.849.849,0,0,0-1.207,0l-4.389,4.385-1.9-1.9A.872.872,0,0,0,19632.93,17394.533Zm11.852,2.537v0l.1,2.85h19.869v-2.85h-19.967Zm-11.852-14.16a.851.851,0,0,0-.6.248l-.729.727a.859.859,0,0,0,0,1.207l3.232,3.232a.864.864,0,0,0,.6.252.85.85,0,0,0,.6-.252l5.721-5.715a.87.87,0,0,0,0-1.213l-.729-.723a.849.849,0,0,0-1.207,0l-4.389,4.389-1.9-1.9A.872.872,0,0,0,19632.93,17382.91Zm11.852,2.537v0l.1,2.85h19.869v-2.85h-19.967Zm-8.18-17.545a1.35,1.35,0,0,0-1.352,1.346v1.83a4.863,4.863,0,0,0,4.859,4.855h16.779a4.863,4.863,0,0,0,4.859-4.855v-1.83a1.347,1.347,0,0,0-1.346-1.346h-5.6a6.3,6.3,0,0,0-12.6,0Zm11.9,2.582a2.583,2.583,0,1,1,2.584-2.582A2.589,2.589,0,0,1,19648.5,17370.484Z"
                transform="translate(-19235.086 -15851)"
                fill="#000060"
            />
        </g>
    </Icon>
)

export default ScheduleIcon
