import { Icon } from '@chakra-ui/react'

const CallCoachIcon = (props) => (
    <Icon viewBox="0 0 137.004 136.998" {...props}>
        <g transform="translate(-344.998 -1479)">
            <path
                id="Exclusion_6"
                data-name="Exclusion 6"
                d="M18616.5,17467a68.307,68.307,0,1,1,26.664-5.385A68.062,68.062,0,0,1,18616.5,17467Zm0-105.4c-10.746,0-19.174,2.826-25.055,8.4-5.92,5.615-8.959,13.748-9.031,24.174a6.658,6.658,0,0,0-3.5,5.861v7.34a6.672,6.672,0,0,0,6.668,6.662h4.838a34.884,34.884,0,0,0,11.445,15.674c4.711,3.611,10.043,5.682,14.633,5.682s9.922-2.07,14.633-5.682a34.87,34.87,0,0,0,11.445-15.674h4.838a6.672,6.672,0,0,0,6.668-6.662v-7.34a6.658,6.658,0,0,0-3.5-5.861c-.072-10.424-3.109-18.557-9.031-24.174C18635.674,17364.428,18627.246,17361.6,18616.5,17361.6Zm0,69.129a19.039,19.039,0,0,1-9.148-3.008,29.759,29.759,0,0,1-9.275-8.795,31.419,31.419,0,0,0,11.7,2.631c.98,1.785,3.35,2.936,6.035,2.936,3.572,0,6.477-2.061,6.477-4.6s-2.9-4.6-6.477-4.6c-2.549,0-4.869,1.068-5.908,2.725a31.543,31.543,0,0,1-8.3-1.49,15.32,15.32,0,0,1-7.008-4.6,30.419,30.419,0,0,1-1.566-9.727c0-.854.025-1.779.076-2.832,6.988-.975,12.6-7.961,12.77-15.9.006-.285.041-.412.115-.412.117,0,.328.307.678.814.217.316.434.631.7.986,0,0,0,.006,0,.008l.008.006c.293.4.594.777.924,1.154,3.842,4.564,8.381,5.961,12.387,7.193,2.424.746,4.518,1.391,6.084,2.621a9.87,9.87,0,0,0-2.129-4.047c6.568.734,12.076,2.748,15.113,5.52.148,1.824.217,3.379.217,4.891C18639.971,17420.664,18624.465,17430.73,18616.5,17430.73Zm-27.51-37.354h0l-2.562,0c.211-9.047,2.891-16.012,7.967-20.7s12.514-7.074,22.105-7.074,17.029,2.381,22.105,7.074,7.756,11.652,7.967,20.7l-2.564,0c-.934-6.111-2.928-11.17-5.928-15.037-4.795-6.17-12.055-9.3-21.58-9.3s-16.785,3.129-21.58,9.3c-3,3.865-4.994,8.924-5.928,15.037Z"
                transform="translate(-18203 -15851)"
                fill="#000060"
            />
        </g>
    </Icon>
)

export default CallCoachIcon
